import BaseBean from "@/utils/BaseBean";
export interface IOrderDataObj {
    utilInst:OrderUtil
    refMap:Map<string,any>
    otherParams: any
}

export default class OrderUtil extends BaseBean{
    public dataObj:IOrderDataObj
    public props:any
    public context:any
    constructor(proxy:any,dataObj:IOrderDataObj,props:any,context:any) {
        super(proxy);
        this.dataObj=dataObj;
        this.props=props;
        this.context=context;
    }
    public async buildSelectData():Promise<any>{
        this.dataObj.otherParams.customerData=await this.utils.OrderProviderApi.getAllCustomer({});//查询所有客户
        this.dataObj.otherParams.brandData=await this.utils.OrderProviderApi.getAllBrand({});//查询所有品牌
        //由于车型和车型公告号要根据品牌联动，所以在这里作一个备份，当发生联动的时候，不用去后台查询了，直接在前端完成过滤，提高效率
        this.dataObj.otherParams.cxDataBak=await this.utils.OrderProviderApi.getAllCx({});//查询所有车型
        this.dataObj.otherParams.cxgghDataBak=await this.utils.OrderProviderApi.getAllCxggh({});//查询所有车型公告号
        //当外界对orderData赋值之后，customerId就有值了，就可以根据customerId查询客户账号、客户收货地址集合，如果这里不行，就把它放到onMounted的nextTick里
        if(this.dataObj.otherParams.orderData.customer.customerId){
            await this.getCustomerAccountByCustomerId(this.dataObj.otherParams.orderData.customer.customerId.value);
            await this.getCustomerDeliveryByCustomerId(this.dataObj.otherParams.orderData.customer.customerId.value);
        }
    }
    //根据客户id查询客户的可用账号
    public async getCustomerAccountByCustomerId(customerId:string):Promise<any>{
        this.dataObj.otherParams.customerAccountData=await this.utils.OrderProviderApi.getCustomerAccountByCustomerId({params:{customerId:customerId,type:0}});//查询所有客户
    }
    //根据客户id查询客户的收货地址
    public async getCustomerDeliveryByCustomerId(customerId:string):Promise<any>{
        this.dataObj.otherParams.customerDeliveryData=await this.utils.OrderProviderApi.getCustomerDeliveryByCustomerId({params:{customerId:customerId}});//查询所有客户
    }
    //初始化每项中的车型、车型公告号下拉数据
    public initCxCxgghData():void{
        this.dataObj.otherParams.orderData.cfgData.forEach((item:any,index:number)=>{
            let {brandId,cxId,cxgghId}=item.pccInfo;
            this.dataObj.otherParams.cxData[index]=this.findCxByBrandId(brandId);//每个子项的车型下拉数据
            this.dataObj.otherParams.cxgghData[index]=this.findCxgghByCxId(cxId);//每个子项的车型公告号下拉数据
        })
    }
    //根据品牌在cxDataBak中过滤出对应的车型
    public findCxByBrandId(brandId:string):Array<any>{
        let result:Array<any>=this.dataObj.otherParams.cxDataBak.filter((item:any)=>item.item.brandId==brandId)
        return result;
    }
    //查看订单的时候，配置每个配置项的车型公告号下拉数据
    public findCxgghByCxId(cxId:string):Array<any>{
        let result:Array<any>=this.dataObj.otherParams.cxgghDataBak.filter((item:any)=>item.item.cxId==cxId)
        return result;
    }
    //车型公告号是否有重复;false-重复，true-不重复
    public checkCxgghDouble(cxgghId:string,index:number):boolean{
        let _index=this.dataObj.otherParams.orderData.cfgData.findIndex((item:any)=>item.pccInfo.cxgghId==cxgghId);
        return _index==-1 || _index==index;
    }
    //计算某行的总金额
    public calRowTotalAmount(options:any):any{
        let totalNum:number=0;
        //1-找到那个配置项的所有车身颜色集合
        let children=this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].children;
        //2-计算这个配置项的车身颜色个数（其实没有必要循环，因为num都是1，所以集合长度其实就是总数量）
        children.forEach((item:any)=>totalNum+=item.num);
        //3-为该行设置总数量
        this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalNum=totalNum;

        //4-为该行设置总金额
        if(this.dataObj.otherParams.orderData.otherInfo.jsType==0){//以人名币结算
            //4-1人名币总和
            this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalRmb=totalNum*options.price;
            //4-2美元总和（按人名币总和乘以利率）
            let totalUsd=totalNum*options.price*this.dataObj.otherParams.orderData.otherInfo.rate;
            this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalUsd=totalUsd;
        }else if(this.dataObj.otherParams.orderData.otherInfo.jsType==1){//以美元结算
            //4-1美元总和
            this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalUsd=totalNum*options.priceUsd;
            //4-2人名币总和（按美元总和乘以利率）
            let totalRmb=totalNum*options.priceUsd*this.dataObj.otherParams.orderData.otherInfo.rate;
            this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalRmb=totalRmb;
        }
        if(options.calTotalType=='rmb'){
            return (this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalRmb).toFixed(2);
        }else{
            return (this.dataObj.otherParams.orderData.cfgData[options.index].itemChildren[options.index1].header.totalUsd).toFixed(2);
        }
    }
}